$(document).ready(function(){

	$('.stories').each((index, element) => {
		const SPEED = 600;

		const module = $(element);
		const touchable =  module.find('.stories-touchable');
		const stories = module.find('ul.stories-list > li');
		const progress = module.find('ul.stories-progress > li');
		const prevButton = module.find('.stories-arrow-prev');
		const nextButton = module.find('.stories-arrow-next');

		const totalStories = stories.length;

		let time = 0;
		let pause = false;
		let currentSpeed = SPEED;
		let currentStoryIndex = 0;
		let players = [];
		let durations = [];

		let interval;

		// INITIALIZE STORIES ////////////////////////////////////////////////////////////////////////////////////
		const init = () => {
			// Initialize videos, if any
			module.find('ul.stories-list > li.is-video').each((index, element) => {
				let i = $(element).data('i');

				players[i] = new Plyr('#player' + i, {
					controls: false,
					resetOnEnd: true,
					loop: { active: false },
					fullscreen: false,
					clickToPlay: false,
					ratio: $(window).width() + ':' + $(window).height(),
					vimeo: { byline: false, portrait: false, title: false, speed: true, transparent: false },
					youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, controls: 0, disablekb: 1, fs: 0, playsinline: 1 }
				});

				players[i].on('ready', e => {
					durations[i] = e.detail.plyr.duration;
				});
			});
		};

		// TIMER TICK ///////////////////////////////////////////////////////////////////////////////////////////
		const tick = () => {
			if(!pause){
				time++;

				let percent = time * 100 / currentSpeed;

				$(progress[currentStoryIndex]).find('.bar').css({'width': percent + '%'});
				if(time === currentSpeed) next();
			}
		};

		// GO TO NEXT STORY //////////////////////////////////////////////////////////////////////////////////////
		const next = () => {
			$(progress[currentStoryIndex]).find('.bar').css({'width': '100%'});
			time = 0;
			currentStoryIndex++;

			if(currentStoryIndex >= totalStories){
				$(progress).find('.bar').css({'width': '0%'});
				currentStoryIndex = 0;
			}

			stories.removeClass('active');
			$(stories[currentStoryIndex]).addClass('active');
			progress.removeClass('active');
			$(progress[currentStoryIndex]).addClass('active');

			if(currentStoryIndex === 0) prevButton.hide();
			else prevButton.show();
			if(currentStoryIndex < totalStories - 1) nextButton.show();
			else nextButton.hide();

			if($(stories[currentStoryIndex]).hasClass('is-video')){
				pause = true;

				let wait = setInterval(function(){
					currentSpeed = durations[currentStoryIndex] * 100;
					if(currentSpeed > 0) clearInterval(wait);
				}, 10);

				players[currentStoryIndex].play();

				players[currentStoryIndex].on('playing', e => {
					pause = false;
				});
			}else{
				currentSpeed = SPEED;
			}
		};

		// GO TO PREVIOUS STORY ////////////////////////////////////////////////////////////////////////////////////
		const prev = () => {
			$(progress[currentStoryIndex]).find('.bar').css({'width': '0%'});
			if(players[currentStoryIndex]) players[currentStoryIndex].stop();
			currentStoryIndex -= 2;
			next();
		};

		// CLICK/TOUCH EVENTS ////////////////////////////////////////////////////////////////////////////////////

		const isTouchDevice = 'ontouchstart' in document.documentElement;
		let itsAClick = false;

		touchable.on(isTouchDevice ? 'touchstart' : 'mousedown', e => {
			pause = true;
			if(players[currentStoryIndex]) players[currentStoryIndex].pause();
			itsAClick = true;

			setTimeout(() => {
				itsAClick = false;
			}, 200);
		});

		touchable.on(isTouchDevice ? 'touchend' : 'mouseup', e => {
			pause = false;

			if(itsAClick){
				if(players[currentStoryIndex]) players[currentStoryIndex].stop();
				next();
			}else{
				if(players[currentStoryIndex]) players[currentStoryIndex].play();
			}

			itsAClick = false;
		});

		// PREV/NEXT ARROWS ////////////////////////////////////////////////////////////////////////////////////

		prevButton.on('click', e => {
			e.preventDefault();
			if(currentSpeed > 0) prev();
		});

		nextButton.on('click', e => {
			e.preventDefault();
			if(currentStoryIndex < totalStories - 1) next();
		});

		// START THE STORIES!! ////////////////////////////////////////////////////////////////////////////////////

		// Initialize
		init();

		// Start the timer and run the stories!
		if(totalStories > 1) interval = setInterval(tick, 10);
		else module.find('ul.stories-progress').hide();
	});
});
